import router from '@/router/index.js';
import { ElMessage } from 'element-plus';
import { getCalculateList, getCalculateAcc, getCalculateRank, getRankboost, getOptionalfeature, getCalculateAgent, buyRankboost, buyPlacementMatches, getCalculateTier, getPlacementMatches, chooseBoosterList, promoCode, buyUnratedGames, getUnratedGames } from "@/api/calcutator";
import { getRegionList } from "@/api/common";
const state = {
  selectLeftId: 1,
  leftList: [],
  regionList: [],
  accList: [],
  rankList: [],
  divisionList: [],
  rrList: [],
  rrList2: [],
  current_rr: null,
  desired_division: null,
  desired_rr: null,
  region_id: null,
  acc_id: null,
  rankList2: [],
  divisionList2: [],
  delivery_time: null,
  total_amount: null,
  //add
  discount_money: null,
  current_division: null,
  featureList: [],
  //add
  // featureList2: [],
  agentList: [],
  payment_type: 1,
  express_order: 1,
  currentRankId: null,
  currentRankIcon: null,
  currentRankName: null,
  currentDivisionName: null,
  desiredRankId: null,
  desiredRankIcon: null,
  desiredRankName: null,
  desiredDivisionName: null,
  tierList: [],
  currentTier_id: null,
  currentTier_name: null,
  numberOfGames: 1,
  promo_code: 'OOFF1',
  boosterList: [],
  boosterId: '',
  //add
  promo_code_check: 'yes',
  //add
  login_username: null,
  login_password: null,
  //add
  total_time: null,
  //add
  tierIcon: null,
  //add
  optionname: null
};
const getters = {};
const mutations = {
  setOptionname(state, optionname) {
    state.optionname = optionname;
  },
  setBoosterId(state, boosterId) {
    state.boosterId = boosterId;
  },
  setBoosterList(state, boosterList) {
    state.boosterList = boosterList;
  },
  setPromoCode(state, promo_code) {
    state.promo_code = promo_code;
  },
  setSelectLeftId(state, selectLeftId) {
    state.selectLeftId = selectLeftId;
  },
  setNumberOfGames(state, numberOfGames) {
    state.numberOfGames = numberOfGames;
  },
  setTierList(state, tierList) {
    state.tierList = tierList;
  },
  setCurrentTierId(state, currentTier_id) {
    state.currentTier_id = currentTier_id;
  },
  setCurrentTierName(state, currentTier_name) {
    state.currentTier_name = currentTier_name;
  },
  setDesiredDivisionName(state, desiredDivisionName) {
    state.desiredDivisionName = desiredDivisionName;
  },
  setDesiredRankId(state, desiredRankId) {
    state.desiredRankId = desiredRankId;
  },
  setDesiredRankIcon(state, desiredRankIcon) {
    state.desiredRankIcon = desiredRankIcon;
  },
  setDesiredRankName(state, desiredRankName) {
    state.desiredRankName = desiredRankName;
  },
  setCurrentDivisionName(state, currentDivisionName) {
    state.currentDivisionName = currentDivisionName;
  },
  setCurrentRankName(state, currentRankName) {
    state.currentRankName = currentRankName;
  },
  setCurrentRankId(state, currentRankId) {
    state.currentRankId = currentRankId;
  },
  setCurrentRankIcon(state, currentRankIcon) {
    state.currentRankIcon = currentRankIcon;
  },
  setExpressorder(state, express_order) {
    state.express_order = express_order;
  },
  setPaymenttype(state, payment_type) {
    state.payment_type = payment_type;
  },
  setRegionId(state, region_id) {
    state.region_id = region_id;
  },
  setAccId(state, acc_id) {
    state.acc_id = acc_id;
  },
  setLeftList(state, leftList) {
    state.leftList = leftList;
  },
  setRegionList(state, regionList) {
    state.regionList = regionList;
  },
  setAccList(state, accList) {
    state.accList = accList;
  },
  setRankList(state, rankList) {
    state.rankList = rankList;
  },
  setDivisionList(state, divisionList) {
    state.divisionList = divisionList;
  },
  setRrList(state, rrList) {
    state.rrList = rrList;
  },
  setRrList2(state, rrList2) {
    state.rrList2 = rrList2;
  },
  setCurrentrr(state, current_rr) {
    state.current_rr = current_rr;
  },
  setDesiredrr(state, desired_rr) {
    state.desired_rr = desired_rr;
  },
  setRankList2(state, rankList2) {
    state.rankList2 = rankList2;
  },
  setDivisionList2(state, divisionList2) {
    state.divisionList2 = divisionList2;
  },
  setDesireddivision(state, desired_division) {
    state.desired_division = desired_division;
  },
  setDesiredrr(state, desired_rr) {
    state.desired_rr = desired_rr;
  },
  setDeliverytime(state, delivery_time) {
    state.delivery_time = delivery_time;
  },
  setTotalamount(state, total_amount) {
    state.total_amount = total_amount;
  },
  //add
  setDiscountamount(state, discount_money) {
    state.discount_money = discount_money;
  },
  setCurrentdivision(state, current_division) {
    state.current_division = current_division;
  },
  setFeatureList(state, featureList) {
    state.featureList = featureList;
  },
  //
  // setFeatureList2(state, featureList2) {
  //     state.featureList2 = featureList2
  // },
  setAgentList(state, agentList) {
    state.agentList = agentList;
  },
  //add
  setPromoCodeCheck(state, promo_code_check) {
    state.promo_code_check = promo_code_check;
  },
  //add
  setLoginUsername(state, login_username) {
    state.login_username = login_username;
  },
  setLoginPassword(state, login_password) {
    state.login_password = login_password;
  },
  //add
  setTotaltime(state, total_time) {
    state.total_time = total_time;
  },
  //add
  setTierIcon(state, tierIcon) {
    state.tierIcon = tierIcon;
  }
};
const actions = {
  reset({
    state,
    commit
  }, value) {
    commit('setAgentList', null);
    commit('setFeatureList', null);
    commit('setCurrentdivision', null);
    commit('setTotalamount', null);
    //add
    commit('setDiscountamount', null);
    commit('setDeliverytime', null);
    commit('setDesiredrr', null);
    commit('setDesireddivision', null);
    commit('setDivisionList2', null);
    commit('setRankList2', null);
    commit('setDesiredrr', null);
    commit('setCurrentrr', null);
    commit('setRrList2', null);
    commit('setRrList', null);
    commit('setDivisionList', null);
    commit('setRankList', null);
    commit('setAccList', null);
    commit('setRegionList', null);
    commit('setLeftList', null);
    commit('setAccId', null);
    commit('setRegionId', null);
    commit('setPaymenttype', 1);
    commit('setExpressorder', 1);
    commit('setCurrentRankId', null);
    commit('setCurrentRankIcon', null);
    //add
    commit('setTierIcon', null);
    commit('setCurrentRankName', null);
    commit('setCurrentDivisionName', null);
    commit('setDesiredRankName', null);
    commit('setDesiredRankId', null);
    commit('setDesiredRankIcon', null);
    commit('setDesiredDivisionName', null);
    commit('setCurrentTierName', null);
    commit('setSelectLeftId', 1);
    commit('setPromoCode', 'OOFF1');
    commit('setNumberOfGames', 1);
    commit('setTierList', null);
    commit('setCurrentTierId', null);
    commit('setBoosterList', null);
    commit('setBoosterId', null);
    //add
    commit('setLoginUsername', null);
    commit('setLoginPassword', null);
    //add
    commit('setTotaltime', null);
    //add
    commit('setOptionname', null);
  },
  //add
  setOptionname({
    state,
    commit
  }, value) {
    commit('setOptionname', value);
  },
  //add
  setLoginUsername({
    state,
    commit
  }, value) {
    commit('setLoginUsername', value);
  },
  setLoginPassword({
    state,
    commit
  }, value) {
    commit('setLoginPassword', value);
  },
  setBoosterId({
    state,
    commit
  }, value) {
    commit('setBoosterId', value);
  },
  setBoosterList({
    state,
    commit
  }, value) {
    commit('setBoosterList', value);
  },
  setPromoCode({
    state,
    commit
  }, value) {
    commit('setPromoCode', value);
  },
  setSelectLeftId({
    state,
    commit
  }, value) {
    commit('setSelectLeftId', value);
  },
  setCurrentTierId({
    state,
    commit
  }, value) {
    commit('setCurrentTierId', value);
  },
  setCurrentTierName({
    state,
    commit
  }, value) {
    commit('setCurrentTierName', value);
  },
  setNumberOfGames({
    state,
    commit
  }, value) {
    commit('setNumberOfGames', value);
  },
  setDesiredDivisionName({
    state,
    commit
  }, value) {
    commit('setDesiredDivisionName', value);
  },
  setDesiredRankId({
    state,
    commit
  }, value) {
    commit('setDesiredRankId', value);
  },
  setDesiredRankIcon({
    state,
    commit
  }, value) {
    commit('setDesiredRankIcon', value);
  },
  setDesiredRankName({
    state,
    commit
  }, value) {
    commit('setDesiredRankName', value);
  },
  setCurrentDivisionName({
    state,
    commit
  }, value) {
    commit('setCurrentDivisionName', value);
  },
  setCurrentRankName({
    state,
    commit
  }, value) {
    commit('setCurrentRankName', value);
  },
  setCurrentRankId({
    state,
    commit
  }, value) {
    commit('setCurrentRankId', value);
  },
  setCurrentRankIcon({
    state,
    commit
  }, value) {
    commit('setCurrentRankIcon', value);
  },
  //add
  setTierIcon({
    state,
    commit
  }, value) {
    commit('setTierIcon', value);
  },
  setExpressorder({
    state,
    commit
  }, value) {
    commit('setExpressorder', value);
  },
  setPaymenttype({
    state,
    commit
  }, value) {
    commit('setPaymenttype', value);
  },
  setRegionId({
    state,
    commit
  }, value) {
    commit('setRegionId', value);
  },
  setAccId({
    state,
    commit
  }, value) {
    commit('setAccId', value);
  },
  setCurrentrr({
    state,
    commit,
    dispatch
  }, value) {
    commit('setCurrentrr', value);
    // if(state.current_rr == null || state.desired_rr== null){

    // }else{
    //     let obj = {
    //         region_id:state.region_id,
    //         acc_id:state.acc_id,
    //         current_rr:state.current_rr,
    //         desired_division:state.desired_division,
    //         desired_rr:state.desired_rr
    //     }
    //     console.log(obj)
    //     dispatch('getRankboost',obj)
    // }
  },
  setDesiredrr({
    state,
    commit,
    dispatch
  }, value) {
    commit('setDesiredrr', value);
    // if(state.desired_rr_rr == null ||state.desired_rr==null){

    // }else{
    //     let obj = {
    //         region_id:state.region_id,
    //         acc_id:state.acc_id,
    //         current_rr:state.current_rr, 
    //         desired_division:state.desired_division,
    //         desired_rr:state.desired_rr
    //     }
    //     console.log(obj)
    //     dispatch('getRankboost',obj)
    // }
  },
  setDivisionList({
    state,
    commit
  }, value) {
    commit('setDivisionList', value);
  },
  setRrList({
    state,
    commit
  }, value) {
    commit('setRrList', value);
  },
  setRrList2({
    state,
    commit
  }, value) {
    commit('setRrList2', value);
  },
  setDivisionList2({
    state,
    commit
  }, value) {
    commit('setDivisionList2', value);
  },
  setDesireddivision({
    state,
    commit
  }, value) {
    commit('setDesireddivision', value);
  },
  setDeliverytime({
    state,
    commit
  }, value) {
    commit('setDeliverytime', value);
  },
  setTotalamount({
    state,
    commit
  }, value) {
    commit('setTotalamount', value);
  },
  //add
  setDiscountamount({
    state,
    commit
  }, value) {
    commit('setDiscountamount', value);
  },
  setCurrentdivision({
    state,
    commit
  }, value) {
    commit('setCurrentdivision', value);
  },
  setFeatureList({
    state,
    commit
  }, value) {
    commit('setFeatureList', value);
  },
  setAgentList({
    state,
    commit
  }, value) {
    commit('setAgentList', value);
  },
  setDesiredrr({
    state,
    commit,
    dispatch
  }, value) {
    commit('setDesiredrr', value);
    // if(state.current_rr == null || state.desired_division == null){

    // }else{
    //     let obj = {
    //         region_id:state.region_id,
    //         acc_id:state.acc_id,
    //         current_rr:state.current_rr,
    //         desired_division:state.desired_division,
    //         desired_rr:state.desired_rr
    //     }
    //     console.log(obj)
    //     dispatch('getRankboost',obj)
    // }
  },
  //add
  setTotaltime({
    state,
    commit
  }, value) {
    commit('setTotaltime', value);
  },
  async getRegionList({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getRegionList(obj);
      if (res) {
        if (res.code == 200) {
          commit('setRegionList', res.data);
          let obj2 = {};
          dispatch('getCalculateRank', obj2);
        }
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getCalculateList({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getCalculateList(obj);
      if (res.code == 200) {
        commit('setLeftList', res.data);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getCalculateAcc({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getCalculateAcc(obj);
      if (res.code == 200) {
        commit('setAccList', res.data);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getCalculateRank({
    commit,
    dispatch,
    state
  }, value) {
    try {
      let obj = value;
      const res = await getCalculateRank(obj);
      if (res.code == 200) {
        commit('setRankList', res.data);
        commit('setRankList2', res.data);
        let obj2 = {
          calculator_id: state.selectLeftId
        };
        dispatch('getCalculateAcc', obj2);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getCalculateTier({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getCalculateTier(obj);
      if (res.code == 200) {
        commit('setTierList', res.data);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getOptionalfeature({
    commit,
    dispatch,
    state
  }, value) {
    try {
      let obj = value;
      const res = await getOptionalfeature(obj);
      if (res.code == 200) {
        //console.log(res.data)
        commit('setFeatureList', res.data);
        //console.log(state.selectLeftId)
        // if(state.selectLeftId !== 1){
        //     commit('setFeatureList', res.data)
        // }else{
        //     let arr = res.data;
        //     let tmp1,tmp2;
        //     arr.forEach((element,index) => {
        //         if(element.id == 2){
        //             tmp1 = index
        //         }
        //         if(element.id == 6){
        //             tmp2 = index
        //         }
        //     });
        //     const temp = arr[tmp1];
        //     arr[tmp1] = arr[tmp2]
        //     arr[tmp2] = temp;
        //     commit('setFeatureList', arr)
        // }

        // let arr = res.data;
        // let tmp1,tmp2;
        // arr.forEach((element,index) => {
        //     if(element.id == 2){
        //         tmp1 = index
        //     }
        //     if(element.id == 6){
        //         tmp2 = index
        //     }
        // });
        // const temp = arr[tmp1];
        // arr[tmp1] = arr[tmp2]
        // arr[tmp2] = temp

        // commit('setFeatureList2', arr)
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getCalculateAgent({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getCalculateAgent(obj);
      if (res.code == 200) {
        commit('setAgentList', res.data);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  //calculate&buy
  async getRankboost({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getRankboost(obj);
      if (res.code == 200) {
        commit('setDeliverytime', res.data.delivery_time);
        commit('setTotalamount', res.data.total_amount);
        commit('setDiscountamount', res.data.discount_money);
        commit('setTotaltime', res.data.total_time);
      } else {}
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async buyRankboost({
    commit,
    dispatch
  }, value) {
    try {
      let loadingInstance = ElLoading.service({
        background: 'rgba(0, 0, 0, 0.2)',
        lock: true
      });
      let obj = value;
      const res = await buyRankboost(obj);
      if (res) {
        if (res.code == 200) {
          window.open(res.data.url, '_self');
          loadingInstance.close();
        } else {
          loadingInstance.close();
        }
      } else {
        loadingInstance.close();
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async buyPlacementMatches({
    commit,
    dispatch
  }, value) {
    try {
      let loadingInstance = ElLoading.service({
        background: 'rgba(0, 0, 0, 0.2)',
        lock: true
      });
      let obj = value;
      const res = await buyPlacementMatches(obj);
      if (res.code == 200) {
        window.open(res.data.url, '_self');
        loadingInstance.close();
        // commit('setDeliverytime',res.data.delivery_time) 
        // commit('setTotalamount',res.data.total_amount) 
      } else {
        loadingInstance.close();
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getPlacementMatches({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getPlacementMatches(obj);
      if (res.code == 200) {
        commit('setDeliverytime', res.data.delivery_time);
        commit('setTotalamount', res.data.total_amount);
        commit('setDiscountamount', res.data.discount_money);
        commit('setTotaltime', res.data.total_time);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async checkCode({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await promoCode(obj);
      if (res) {
        if (res.code == 200) {
          if (res.data.status == 1) {
            commit('setPromoCodeCheck', 'yes');
          } else if (res.data.status == 0) {
            commit('setPromoCodeCheck', 'no');
          } else {
            commit('setPromoCodeCheck', 'no');
          }
          //ElMessage.success('Promocode success');
        }
        // else {
        //     commit('setPromoCodeCheck','no')
        //     //commit('setPromoCode', null);
        //     //ElMessage.error(res.message);
        // }
      } else {
        //commit('setPromoCode', null);
        //commit('setPromoCodeCheck','no')
        ElMessage.error(res.message);
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async getBoosterList({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await chooseBoosterList(obj);
      if (res) {
        if (res.code == 200) {
          let arr = [{
            id: 0,
            username: 'Currently Online'
          }];
          let arr2 = arr.concat(res.data.data);
          commit('setBoosterList', arr2);
        } else {
          //ElMessage.error(res.message);
        }
      } else {
        //ElMessage.error(res.message);
      }
    } catch (e) {
      console.log('error========');
      //console.error(e)
    }
  },
  //add
  setPromoCodeCheck({
    state,
    commit
  }, value) {
    commit('setPromoCodeCheck', value);
  },
  //add
  async getUnratedGames({
    commit,
    dispatch
  }, value) {
    try {
      let obj = value;
      const res = await getUnratedGames(obj);
      if (res.code == 200) {
        commit('setDeliverytime', res.data.delivery_time);
        commit('setTotalamount', res.data.total_amount);
        commit('setDiscountamount', res.data.discount_money);
        commit('setTotaltime', res.data.total_time);
      } else {}
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  },
  async buyUnratedGames({
    commit,
    dispatch
  }, value) {
    try {
      let loadingInstance = ElLoading.service({
        background: 'rgba(0, 0, 0, 0.2)',
        lock: true
      });
      let obj = value;
      const res = await buyUnratedGames(obj);
      if (res.code == 200) {
        window.open(res.data.url, '_self');
        loadingInstance.close();
        // commit('setDeliverytime',res.data.delivery_time) 
        // commit('setTotalamount',res.data.total_amount) 
      } else {
        loadingInstance.close();
      }
    } catch (e) {
      console.log('error========');
      console.error(e);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};