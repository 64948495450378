import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import store from "./store/index.js";
import i18n from "./lang";
import './utils/rem'
// import './assets/style/global.css'
import ElementUI from 'element-plus'
import 'element-plus/dist/index.css';
import '@/assets/style/root.css';
import "@/assets/fonts/font.css"

import VueCropper from 'vue-cropper'; 
import 'vue-cropper/dist/index.css'

import VueDragReSize from "@gausszhou/vue3-drag-resize";

import Lazyload from "vue3-lazyload";
import Vue3TouchEvents from "vue3-touch-events";
// import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App);

/** 定义一个函数，用于对象链式取值 */
import { getSDK } from './utils/openIM';
app.config.globalProperties.$OpenIM = getSDK();






app.component("VueDragReSize", VueDragReSize);
app.use(i18n)
app.use(store)
app.use(router)
app.use(VueCropper)
app.use(ElementUI)
app.use(Lazyload)
app.use(Vue3TouchEvents)
// app.use(vue3GoogleLogin, {
//     clientId: '476228445932-s6g74ouvvibmlr19af3d2dv7in63vpte.apps.googleusercontent.com',
//     scope: 'email profile openid'
// })
.mount("#app");
