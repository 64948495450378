import request from "@/utils/request"
export function getRegionList(data){
    return request({
        url: "/v1/web/index/region",
        method: "post",
        data
    });
}
export function getCountryList(data){
    return request({
        url: "/v1/web/index/country",
        method: "post",
        data
    });
}
export function jobApply(data){
    return request({
        url: "/v1/web/job/apply",
        method: "post",
        data
    });
}
export function indexLeaderboard(data){
    return request({
        url: "/v1/web/index/leaderboard",
        method: "post",
        data
    });
}
export function indexReviews(data){
    return request({
        //url: "/v1/web/index/reviews",
        //url: "/v1/web/booster/profile/reviews",
        url: "/v1/web/index/order/review",
        method: "post",
        data
    });
}
export function indexBlog(data){
    return request({
        url: "/v1/web/index/blog",
        method: "post",
        data
    });
}
export function orderReview(data){
    return request({
        url: "/v1/web/index/order/review",
        method: "post",
        data
    });
}
export function reviewCount(data){
    return request({
        url: "/v1/web/index/review/count",
        method: "post",
        data
    });
}
export function getBlogDetail(data){
    return request({
        url: "/v1/web/index/blog/detail",
        method: "post",
        data
    });
}